.line-container {
    position: relative;
    padding-top: 22.25px;
}

.line {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
}

.text-container {
    position: relative;
    z-index: 1; /* Place text above the line */
}

.centered-text {
    text-align: left;
    padding: 0 20px;
    font-weight: bold;
    font-size: 1.25rem;
}
#greenbtn {
    background-color: green !important;
}

#greybtn {
    background-color: lightgrey !important;
}
.float-left {
    float: left !important;
}