.customspinner {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99999999999999999999999999999999999999999999999999999999999999999999999 !important;
    background-color: rgba(0, 0, 0, 0.525) !important;
}
#remember-me {
    float: left !important;
    text-align: left !important;
}