.dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
}
.dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
}
.btn-light {
    color: #2cdd9b;
    background-color: #e5f7f0;
    border-color: #d8f7eb;
}
#btntext {
    color: white !important;
    max-height: 32.25px !important;
}
@media only screen and (max-width: 775px) {
    #specialspanmobile {
        display: none !important;
    }
    #marginleftmobile {
        margin-left: -35px !important;
    }
}