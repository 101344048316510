
.gray-bg {
    background-color: #f5f5f5;
}
.special-img-two {
    max-width: 100%;
    position: absolute;
    top: 0;
    transform: rotate(270deg);
    z-index: 0 !important;
    left: 0;
    max-height: 350px;
    object-fit: contain;
}
.special-img {
    object-fit: cover;
    max-width: 100%;
    max-height: 600px;
    
}
@media only screen and (max-width: 750px) {
    .special-img {
        display: none !important;
    }
    #verytopmobile {
        margin-top: 220px;
    }
}
/* About Me 
---------------------*/
.about-text h3 {
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 6px;
  text-align: left;
}
@media (max-width: 767px) {
  .about-text h3 {
    font-size: 35px;
    text-align: left;
  }
}
.about-text h6 {
  font-weight: 600;
  margin-bottom: 15px;
  text-align: left;
}
@media (max-width: 767px) {
  .about-text h6 {
    font-size: 18px;
    text-align: left;
  }
}
.about-text p {
  font-size: 18px;
  text-align: left;
  margin-left: -10px;
}
.about-text p mark {
  font-weight: 600;
  color: #20247b;
}
.about-text {
    background-color: white;
    padding: 12.25px;
    z-index: 10 !important;
}
.about-list {
  padding-top: 10px;
  border-radius: 12.25px;
}
.about-list .media {
  padding: 5px 0;
}
.about-list label {
  color: #20247b;
  font-weight: 600;
  width: 88px;
  margin: 0;
  position: relative;
}
.about-list label:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  width: 1px;
  height: 12px;
  background: #20247b;
  -moz-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
  margin: auto;
  opacity: 0.5;
}
.about-list p {
  margin: 0;
  font-size: 15px;
}
.about-avatar {
    z-index: 0 !important;
}
@media (max-width: 991px) {
  .about-avatar {
    margin-top: 30px;
    z-index: 0 !important;
  }
}
.about-list {
    background-color: white;
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}
.about-section .counter {
  padding: 22px 20px;
  background: #ffffff;
  z-index: -1 !important;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}
.about-section .counter .count-data {
  margin-top: 10px;
  margin-bottom: 10px;
}
.about-section .counter .count {
  font-weight: 700;
  color: #20247b;
  margin: 0 0 5px;
}
.about-section .counter p {
  font-weight: 600;
  margin: 0;
}
.about-section {
    background-image: url("../../assets/images/disccc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
mark {
    background-image: linear-gradient(rgba(252, 83, 86, 0.6), rgba(252, 83, 86, 0.6));
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-color: transparent;
    padding: 0;
    color: currentColor;
}
.theme-color {
    color: #fc5356;
}
.dark-color {
    color: #20247b;
}
#about {
  min-width: 100vw !important;
}
.about-images {
  background-color: white !important;
  padding: 10;
}
.about-content {
    background-color: white;
}
.about-us-container {
  /* font-family: Arial, sans-serif; */
}

.header {
  display: flex;
}

.image-left, .image-right {
  width: 50%;
}

.image-left img, .image-right img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.content-secondary {
  display: flex;
  margin: 20px;
}

.sidebar {
  width: 20%;
  border-right: 1px solid #ccc;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}
.banner-image-about {
  min-width: 100%;
  object-fit: cover;
  max-height: 325px;
  min-height: 325px;
  margin: 0px 0px 32.25px -17.25px;
}
.sidebar li {
  padding: 10px 15px;
  cursor: pointer;
}

.sidebar li.active {
  font-weight: bold;
  color: #914;
}
#list-item-accordian {
  border-bottom: 1px solid rgb(238, 238, 238) !important;
}
#list-item-accordian-mobile {
  border-bottom: 1px solid rgb(238, 238, 238) !important;
  margin: 22.25px 0px;
}
.main-content-secondary {
  width: 80%;
  background-color: #F7F7F7;
  padding: 15.25px;
}

.main-content-secondary h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.main-content-secondary p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.social-link {
  display: flex;
  align-items: center;
}

.social-link img {
  /* width: 30px;
  height: 30px; */
  margin-right: 10px;
}
.team-container {
  /* font-family: Arial, sans-serif; */
  margin: 20px;
  padding: 20px;
  color: #333;
  max-width: 800px;
  line-height: 1.6;
}

.team-header h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
}
.min-line-height-content {
  
}
.team-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.team-info {
  margin-right: 20px;
}

.team-info h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.team-info h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.team-info p {
  margin: 5px 0;
  font-size: 16px;
}

.team-image {
  max-width: 250px;
}

.team-image img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.team-bio {
  margin-top: 20px;
}

.team-bio p {
  margin-bottom: 15px;
  text-align: justify;
}
@media only screen and (max-width: 776px) {
  .main-content-secondary {
    width: 100%;
    background-color: #F7F7F7;
    padding: 15.25px;
  }
  
  .main-content-secondary h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .main-content-secondary p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  .banner-image-about {
    object-fit: contain !important;
    min-width: 100% !important;
    margin: -50px 0px;
  }
}
.image-bottom {
  max-width: 100% !important;
}