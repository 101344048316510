.register-content {
    display:flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    width: 80vw;    
    margin: 90px auto;
}
.register-content input {
    width: calc(100% - 22px); /* Adjusted width to account for padding and border */
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box; /* Ensures the padding and border are included in the total width */
    align-self: center;
    margin: auto;
}
.spacing-input {
    margin-top: 15px !important;
    min-width: 100% !important;
}
.inputicon {
    max-width: 30px;
    margin-top: 5px;
    max-height: 30px;
}
.inputicon:hover { 
    cursor: pointer;
}
.spacing-input-left {
    margin-top: 15px !important;
    float: left !important;
}
#register-form > div.row > div > .react-datepicker-wrapper {
    float: left !important;
}
.imageupload {
    max-width: 225px;
    max-height: 225px;
    border-radius: 17.25px;
    box-shadow: 10px 5px 5px grey;
}
@media only screen and (max-width: 775px) {
   .register-content  {
        min-width: 100vw !important;
    }
}
#root > div > div > div.register-content.container > div > div:nth-child(3) > div > button {
    color: #2C849A; /* Text color */
    background-color: rgba(44, 132, 154, 0.2); /* Background color (semi-transparent) */
    border: 2px solid #2C849A; /* Border color */
    padding: 10px 20px; /* Spacing inside the button */
    font-size: 16px; /* Font size */
    font-weight: 600; /* Slightly bold text */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    margin: 25px !important;
}
/* Default button styling */
#root > div > div > div.register-content.container > div > div:nth-child(3) > div > button {
    color: #2C849A; /* Text color */
    background-color: rgba(44, 132, 154, 0.2); /* Background color (semi-transparent) */
    border: 2px solid #2C849A; /* Border color */
    padding: 10px 20px; /* Spacing inside the button */
    font-size: 16px; /* Font size */
    font-weight: 600; /* Slightly bold text */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* Styling for disabled button */
#root > div > div > div.register-content.container > div > div:nth-child(3) > div > button:disabled {
    color: #A9A9A9; /* Light gray text */
    background-color: #E0E0E0; /* Light gray background */
    border: 2px solid #D3D3D3; /* Light gray border */
    cursor: not-allowed; /* Disabled cursor */
    opacity: 0.6; /* Slight transparency */
}
#root > div > div > div.register-content.container > div > ol {
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
    text-align: center !important;
}
.reg-label {
    font-weight: bold;
    margin-top: 12.25px;
    margin-bottom: 12.25px;
    text-align: left;
    float: left;
}