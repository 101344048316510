/* #nav-bar > div {
    position: absolute !important;
    max-height: 100px !important;
} */
#redbutton {
    background-color: rgb(156, 0, 0) !important;
}
#redbutton a {
    color: white !important;
}
@media only screen and (max-width: 775px) {
    #nav-bar {
        margin-left: -60px;
    }
}
.profile-icon-container {
    margin-left: 32.25px;
    margin-bottom: 10px;
}
#absolutebtn {
    position: absolute !important;
    top: 60px;
    right: 275px;
}
#absolutebtnlogin {
    position: absolute !important;
    top: 50px;
    right: 275px;
}
#absolutebtntwo {
    position: absolute !important;
    right: 200px;
    top: 60px;
    min-width: 45px;
    width: 45px;
    height: 45px;
    min-height: 45px;
    z-index: 0 !important;
}
.nav-link-spacing {
    margin: 0px 15px;
    color: black;
    font-family: 'Poppins', sans-serif !important;
}
@media only screen and (max-width: 775px) {
    .logo-container, .display-none {
        display: none !important;
    }
    #absolutebtnlogin {
        display: none !important;
    }
}
.nav-link:hover {
    cursor: pointer;
    color: darkred;
}
.navbar-brand:hover { 
    cursor: pointer !important;
}
.Dropdown-control {
    background: none !important;
    right: -40px !important;
    margin-top: 0px !important;
    border: none !important;
}
.Dropdown-arrow {
    display: none !important;
}
.Dropdown-placeholder .is-selected, .Dropdown-placeholder {
    color: black !important;
}