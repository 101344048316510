.d-middle {
    align-items: center;
    display: flex;
    justify-content: center;
}

.opacity-8,.overlay-opacity-8:after {
    opacity: .45
}
.clickable-resenda span:hover {
    cursor: pointer;
    color: red;
}
.clickable-resenda span {
    margin-bottom: 20px !important;
}
.miminumheight {
    min-height: 75vh;
    height: 75vh;
}
.addbacked {
    background-image: url("../../../assets/gradient-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 75vh;
    height: 75vh;
}
@media only screen and (max-width: 775px) {
    .miminumheight {
        min-height: 100%;
        height: 100%;
    }
    .addbacked {
        background-image: url("../../../assets/gradient-2.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
        height: 100%;
    }
    .spacertop {
        padding-top: 50px;
        padding-bottom: 40px;
    }
}
opacity-8, .overlay-opacity-8:after {
    opacity: .45;
}
.overlay-dark-hover:hover:after, .overlay-dark:after {
    background: #131f43;
}
.overlay-dark-hover:after, .overlay-dark:after, .overlay-light-hover:after, .overlay-light:after {
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
}

.bg-theme-color-light {
    background-color: rgba(87,79,236,.04)!important;
}
.bg-cover {
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}
.overlay-dark, .overlay-dark-hover, .overlay-light, .overlay-light-hover {
    position: relative;
}
#overlay-default, .overlay-dark, .overlay-dark-hover:after, .overlay-default, .overlay-light, .overlay-light-hover:after {
    transition: all .2s ease;
}
.section, section {
    position: relative;
    word-break: break-word;
}

.overlay-dark>*, .overlay-light>* {
    position: relative;
    z-index: 1;
}