.sliderimage {
    max-width: 100px;
    max-height: 100px;
}
.centered-two {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    padding: 17.25px 0px;
    vertical-align: center;
}
.profile-page {
    max-height: 300px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    vertical-align: center;
}
#johnimage:hover {
    cursor: pointer !important;
}
.widget {
    border: none;
    box-shadow: 0 2px 0 rgba(0,0,0,.07);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    background: #fff;
    padding: 20px;
    display: block;
}

.widget-gallery-header {
    margin: -20px -20px 0;
    padding: 20px;
    border-radius: 5px 5px 0 0;
}

.widget-gallery-list {
    list-style-type: none;
    margin: -5px -20px -20px;
    padding: 3px 0 0 3px;
}

.widget-gallery-list>li {
    width: 25%;
    position: relative;
    padding-top: 25%;
    overflow: hidden;
    float: left;
}

.widget-gallery-list>li a {
    background: #EAEDEF;
    color: #aab3ba;
    text-decoration: none;
}

.widget-gallery-list>li img {
    position: absolute;
    right: 3px;
    bottom: 3px;
    object-fit: cover !important;
    max-height: 100%;
}

.widget-gallery-header p {
    font-size: 12px;
}

.widget-gallery-header h5, .widget-gallery-header p {
    margin: 0;
}

.text-muted {
    color: #aab3ba;
}

.m-t-3 {
    margin-top: 3px!important;
}

.widget-gallery-list>li a:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all .2s ease-in-out;
    background: 0 0;
    z-index: 1020;
    border-radius: 50%;
}
.customhomepageimg {
    min-height: 80vh;
    min-width: 75vw;
    object-fit: cover;
}
.widget-gallery-list>li:hover a:before {
    content: '';
    left: 0;
    right: 3px;
    top: 0;
    bottom: 3px;
    background: rgba(0,0,0,.3);
    border-radius: 0;
    cursor: pointer;
}
.card-container {
    min-height: 45.25vh !important;
}
@media only screen and (max-width: 775px) {
    .card-container {
        min-height: 65vh !important;
        margin-top: 50px;
    }
    .card-container h3 {
        margin-top: 75px;
    }
}
@media only screen and (max-width: 400px) {
    .card-container {
        min-height: 80vh !important;
        margin-top: 50px;
    }
    .card-container h3 {
        margin-top: 75px;
    }
}
.image-custom-landing {
    max-width: 25px;
    max-height: 25px;
    margin-right: 22.25px;
    position: absolute;
}
.img--custom {
    min-width: 100%;
    min-height: 325px;
    height: 325px;
    object-fit: cover;
}
.services-container {
    text-align: left;
    padding: 20px;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.service-item {
    text-align: center;
}
.hoverbtn:hover {
    cursor: pointer;
    background-color: white !important;
    box-shadow: 0 4px 6px rgba(49, 134, 156, 0.1) !important;
}
.service-image:hover, .service-image-tall:hover, .service-image-short:hover {
    cursor: pointer;
    -webkit-box-shadow: 10px 5px 5px 0px rgb(184, 184, 184);
    -moz-box-shadow: 5px 5px 5px 0px rgb(184, 184, 184);
    box-shadow: 5px 5px 5px 0px rgb(184, 184, 184);
    border: 1.75px solid burlywood;
    transform: scale(0.85); /* Start slightly zoomed in */
    opacity: 0.7; /* Faded out initially */
    transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition */
}
.service-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 485px;
    border-radius: 8px;
}

.service-image-short {
    width: 100%;
    height: 215px;
    object-fit: cover;
    border-radius: 8px;
}

.service-image-tall {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 485px;
    border-radius: 8px;
}
.rowbackground {
    background-color: #F7F7F7;
}
.service-image-tall-custom {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 485px;
    border-radius: 8px;
}
#boxshadow {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(224,224,224,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(224,224,224,1);
    box-shadow: 10px 10px 5px 0px rgba(224,224,224,1);
}