.image-slider-custom img {
    max-width: 225px !important;
    min-width: 225px !important;
    max-height: 150px !important;
    min-height: 150px !important;
    padding: 17.25px !important;
}
.image-slider-custom {
    max-width: 225px !important;
    min-width: 225px !important;
    max-height: 150px !important;
    min-height: 150px !important;
    object-fit: contain !important;
}
.specialsearchinput {
    min-width: 20vw;
}
.list-styled-item {
    margin-top: 12.25px;
    padding: 5px;
    min-width: 17.5vw !important;
}
.list-styled-item:hover {
    cursor: pointer !important;
}
#fixside > div.col-md-5.col-lg-5.col-xl-5.col-sm-12 > div > div.select-search-value > input {
    background-color: white !important;
    color: black !important;
}
.position-bottom {
    /* position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); */
    margin-top: 50px;
}

.select-search-options { 
    background-color: white !important;
    z-index: 9999999999999999999 !important;
}
.select-search-container {
    max-width: calc(100% + 120px) !important;
}
.select-search-options li {
    margin-top: 22.25px !important;
    margin-bottom: 22.25px !important; 
    background-color: white !important;
    min-width: 475px !important;
    z-index: 9999999999999999999 !important;
}
.cardddddcustom {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eff0f2;
    border-radius: 1rem;
    -webkit-box-shadow: 10px 10px 5px 0px rgb(203, 201, 201);
    -moz-box-shadow: 10px 10px 5px 0px rgba(196,196,196,1);
    box-shadow: 10px 10px 5px 0px rgba(196,196,196,1);
}
.avatar-md {
    height: 4rem;
    width: 4rem;
}
.rounded-circle {
    border-radius: 50%!important;
    min-width: 60px !important;
    min-height: 50px !important;
}
.img-thumbnail-resize {
    padding: 0.25rem;
    /* background-color: #f1f3f7; */
    border: 1px solid #eff0f2;
    border-radius: 0.75rem;
    object-fit: contain;
}
.img-thumbnail {
    padding: 0.25rem;
    /* background-color: #f1f3f7; */
    border: 1px solid #eff0f2;
    border-radius: 0.75rem;
    max-width: 235px !important;
    max-height: 235px !important;
}
.avatar-title {
    align-items: center;
    background-color: #3b76e1;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.bg-soft-primary {
    background-color: rgba(59,118,225,.25)!important;
}
a {
    text-decoration: none!important;
}
.badge-soft-danger {
    color: #f56e6e !important;
    background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
    color: #63ad6f !important;
    background-color: rgba(99,173,111,.1);
}
.mb-0 {
    margin-bottom: 0!important;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.75rem;
}
.btn-soft-primary {
    background-color: black; 
    color: white;
    border-radius: 6.25px;
}
.burgendy-text {
    color: #800020 !important;
}
.btn-primary-custom {
    background-color:#800020; 
    color: white;
    border-radius: 6.25px;
}
#nodisplay {
    display: none !important;
}
.iconed-three {
    max-width: 32.5px;
    max-height: 32.5px;
    /* position: absolute;
    left: 22.5 !important;
    top: 22.5 !important; */
}

.grid {
    position: relative;
    width: 100%;
    background: #fff;
    color: #666666;
    border-radius: 2px;
    margin-bottom: 25px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.grid .grid-body {
    padding: 15px 20px 15px 20px;
    font-size: 0.9em;
    line-height: 1.9em;
}

.search table tr td.rate {
    color: #f39c12;
    line-height: 50px;
}

.search table tr:hover {
    cursor: pointer;
}
#fixside {
    background-color: rgb(255, 255, 255) !important;
    -webkit-box-shadow: 10px 10px 5px 0px rgb(203, 201, 201);
    -moz-box-shadow: 10px 10px 5px 0px rgba(196,196,196,1);
    box-shadow: 10px 10px 5px 0px rgba(196,196,196,1);
    border: 0.75px solid black;
    border-radius: 15px;
    padding: 12.25px;
    margin-bottom: 22.25px;
}
.search table tr td.image {
	width: 50px;
}

.search table tr td img {
	width: 50px;
	height: 50px;
}

.search table tr td.rate {
	color: #f39c12;
	line-height: 50px;
}

.search table tr td.price {
	font-size: 1.5em;
	line-height: 50px;
}

.search #price1,
.search #price2 {
	display: inline;
	font-weight: 600;
}
.leadfour {
    color: green;
    font-weight: bold;
    text-decoration-line: underline;
    text-align: left;
}
.leadfive {
    color: red;
    font-weight: bold;
    text-decoration-line: underline;
    text-align: left;
}
@media only screen and (max-width: 775px) {
    #selectmobile {
        min-width: 70vw !important;
    }
    #movemobile {
        display: none !important;
    }
    #onlyshowonmobile {
        padding-top: 28.25px;
    }
    #selectmobiletwo {
        min-width: 75vw !important;
    }
    #fixside {
        margin-left: -22.5px;
        width: calc(100vw - 2.25px);
    }
}
@media only screen and (min-width: 776px) {
    #onlyshowonmobile {
        display: none !important;
    }
}

.select-search {
    background-color: white !important; /* Always white */
    color: black !important; /* Text color */
}

.select-search__option {
    background-color: white !important; /* Dropdown options */
    color: black !important; /* Dropdown text color */
}

.select-search__input {
    background-color: white !important; /* Input field */
    color: black !important; /* Input text color */
}
.select-search-value, .select-search-input {
    background-color: white !important;
    color: black !important;
    max-height: 37.50px !important;
}
.buttoncustom {
    color: white;
    padding: 4.25px;
    border-radius: 7.25px;
    background-color: black;
}