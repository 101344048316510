.blogFormContainer {
    background-color: white;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.blogFormTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 16px;
}
.font-medium {
    color: black !important;
}
.blogFormGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}
.bg-blue-600 {
    background-color: black;
}
.blogFormItem {
    display: flex;
    flex-direction: column;
}

.blogFormItem label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 4px;
}

.blogFormItem input,
.blogFormItem textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out;
}

.blogFormItem input:focus,
.blogFormItem textarea:focus {
    border-color: #007bff;
    outline: none;
}

.blogFormContent {
    grid-column: span 2;
}

.blogFormItem textarea {
    height: 100px;
    resize: vertical;
}

.blogFormActions {
    grid-column: span 2;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}

.blogFormActions button {
    background-color: #007bff;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.2s ease-in-out;
}

.blogFormActions button:hover {
    background-color: #0056b3;
}
  