.blog-grid {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
}
.blog-grid .blog-img {
    position: relative;
}
.blog-grid .blog-img .date {
    position: absolute;
    background: #fc5356;
    color: #ffffff;
    padding: 8px 15px;
    left: 10px;
    top: 10px;
    border-radius: 4px;
}
.blog-grid .blog-img .date span {
    font-size: 22px;
    display: block;
    line-height: 22px;
    font-weight: 700;
}
.blog-grid .blog-img .date label {
    font-size: 14px;
    margin: 0;
}
.blog-grid .blog-info {
    padding: 20px;
}
.blog-grid .blog-info h5 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 10px;
}
.blog-grid .blog-info h5 a {
    color: #20247b;
}
.blog-grid .blog-info p {
    margin: 0;
}
.blog-grid .blog-info .btn-bar {
    margin-top: 20px;
}


/* Blog Sidebar
-------------------*/
.blog-aside .widget {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
.blog-aside .widget-body {
    padding: 15px;
}
.blog-aside .widget-title {
    padding: 15px;
    border-bottom: 1px solid #eee;
}
.blog-aside .widget-title h3 {
    font-size: 20px;
    font-weight: 700;
    color: #fc5356;
    margin: 0;
}
.blog-aside .widget-author .media {
    margin-bottom: 15px;
}
.blog-aside .widget-author p {
    font-size: 16px;
    margin: 0;
}
.blog-aside .widget-author .avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
}
.blog-aside .widget-author h6 {
    font-weight: 600;
    color: #20247b;
    font-size: 22px;
    margin: 0;
    padding-left: 20px;
}
.blog-aside .post-aside {
    margin-bottom: 15px;
}
.blog-aside .post-aside .post-aside-title h5 {
    margin: 0;
}
.blog-aside .post-aside .post-aside-title a {
    font-size: 18px;
    color: #20247b;
    font-weight: 600;
}
.blog-aside .post-aside .post-aside-meta {
    padding-bottom: 10px;
}
.blog-aside .post-aside .post-aside-meta a {
    color: #6F8BA4;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
}
.blog-aside .latest-post-aside + .latest-post-aside {
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin-top: 15px;
}
.blog-aside .latest-post-aside .lpa-right {
    width: 90px;
}
.blog-aside .latest-post-aside .lpa-right img {
    border-radius: 3px;
}
.blog-aside .latest-post-aside .lpa-left {
    padding-right: 15px;
}
.blog-aside .latest-post-aside .lpa-title h5 {
    margin: 0;
    font-size: 15px;
}
.blog-aside .latest-post-aside .lpa-title a {
    color: #20247b;
    font-weight: 600;
}
.blog-aside .latest-post-aside .lpa-meta a {
    color: #6F8BA4;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
}

.tag-cloud a {
    padding: 4px 15px;
    font-size: 13px;
    color: #ffffff;
    background: #20247b;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
}
.tag-cloud a:hover {
    background: #fc5356;
}

.blog-single {
    padding-top: 30px;
    padding-bottom: 30px;
}

.article {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    padding: 15px;
    margin: 15px 0 30px;
}
.article .article-title {
    padding: 15px 0 20px;
}
.article .article-title h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
}
.article .article-title h6 a {
    text-transform: uppercase;
    color: #fc5356;
    border-bottom: 1px solid #fc5356;
}
.article .article-title h2 {
    color: #20247b;
    font-weight: 600;
}
.article .article-title .media {
    padding-top: 15px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
}
.article .article-title .media .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
}
.article .article-title .media .media-body {
    padding-left: 8px;
}
.article .article-title .media .media-body label {
    font-weight: 600;
    color: #fc5356;
    margin: 0;
}
.article .article-title .media .media-body span {
    display: block;
    font-size: 12px;
}
.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
    color: #20247b;
    font-weight: 600;
    margin-bottom: 15px;
}
.article .article-content blockquote {
    max-width: 600px;
    padding: 15px 0 30px 0;
    margin: 0;
}
.article .article-content blockquote p {
    font-size: 20px;
    font-weight: 500;
    color: #fc5356;
    margin: 0;
}
.article .article-content blockquote .blockquote-footer {
    color: #20247b;
    font-size: 16px;
}
.article .article-content blockquote .blockquote-footer cite {
    font-weight: 600;
}
.article .tag-cloud {
    padding-top: 10px;
}

.article-comment {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    padding: 20px;
}
.article-comment h4 {
    color: #20247b;
    font-weight: 700;
    margin-bottom: 25px;
    font-size: 22px;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
#containerwhite { 
    background: white !important;
    border: 1.25px solid grey;
    margin-bottom: 27.25px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    margin-top: 27.25px;
    border-radius: 18.25px;
}
.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Adjust spacing between tags */
}

.tag {
    background-color: #9995f1; /* Light gray background */
    border: 2px solid black;
    color: #fff; /* Dark text */
    padding: 4.25px 7.5px;
    border-radius: 3.25px; /* Rounded edges */
    font-size: 15.75px;
    font-weight: bold;
    white-space: nowrap; /* Prevents breaking inside the tag */
}
  