
/* font style import from fonts.google.com*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,300;1,400&display=swap');


:root {
    --bordo: #550722;
    --gray: #647A92;
    --beige: #BEB9B2;
}

*{
    margin: 0;
    padding: 0;
}
.text-dark {
    color: black !important;
}
body {
    font-family: 'Abhaya Libre', serif, Arial, sans-serif !important;
    scroll-behavior: smooth;
}
header, 
footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: white; 
    padding: 0 20px; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
}

/* Logo */
.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    width: 100%; /* Adjust the size of the logo to fill the available space */
    max-width: 170px; /* Set a maximum width for the logo */
    height: auto; /* Allow the height to adjust proportionally */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.logo img {
    width: 100%; 
}
.copyright { 
    color: black !important;
}
/* Login Button and Nav */

.header-right,
.footer-right {
    display: flex;
    align-items: center;
}

.btn {
    display: inline-flex;
    padding: 10px 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #2C849A !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;
}


.btn a {
    color: white; 
    text-decoration: none; 
    font-weight: bold;
}

.btn:hover a {
    color: #000; /* Change link color on hover */
}

.btn:hover {
    background-color: white; /* Change background color on hover */
}

nav,
.nav-bar{
    display: flex;
    flex-direction: column; /* Change to column on smaller screens */
    align-items: center;
    height: auto; /* Allow the height to adjust based on content */

}
.nav-menu {
    position: relative;
    display: inline-block;
}

.nav-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    cursor: pointer;
    margin-right: 60px;
    margin-left: 60px;
}

.nav-icon span {
    height: 2px;
    background-color: black;
    transition: all 0.3 ease-in-out;
}


.menu-links{
    position: fixed;
    margin-left:auto;
    top: 0;
    right: 0; 
    background-color: white;
    width: 100%;
    max-width:350px;
    height: 100vh; 
    flex-shrink: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    z-index: 100; 
}

.menu-links a:hover,
.foot-links a:hover {
    text-decoration: underline; /* Add underline on hover */
    color:  var(--bordo);/* Change text color on hover */
}

.menu-links.open {
    max-height: 100vh; 
    right: 0; 
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    margin-top: 40px;
    margin-right: 90px;
}

.close-icon img {
    width: 50px;
    height: 50px;
}

.menu-links a,
.foot-links a{
    display: inline-flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    transition: all 0.3 ease-in-out;
    color: var(--bordo);
    text-decoration: none;
    padding: 0 10px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 50px;
}

.links{
    margin-top: 100px;
}

.menu-links li,
.foot-links li
{
    list-style: none;
    top: 100px;
}

.content,
.middle-content,
.small-content {
    background-image: url('./assets/images/another.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh; /* Adjust to fill the viewport */
    position: relative;
    padding-top: 80px; /* Adjusted padding for the header */
}

.small-content {
    min-height: 10vh; /* Adjust to small the viewport */
}

.middle-content {
    min-height: 30vh;
}

/* Content of the Current Auction Card, Buyers/Sellers Card */

.card-container,
.small-card-container {
    position: absolute;
    top: 35%;
    right: 50px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    border-radius: 25px;
    width: 500px;
    height: 375px;
}

.small-card-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 120px;
}

.card-container h2, .card-container p, .card-container .btn{
    margin: 30px;
    text-align: center; /* Center the text content */ 
}


.small-card-container h2, p {
    text-align: center;
    padding: 10px 15px;
}


.card-container h2,
.small-card-container h2 {
    padding: 14px;
}

.small-card-container h2 {
    border-bottom: 1px solid  var(--bordo); 
}

.card-container p, 
.small-card-container p {
    padding: 5px 50px;
    gap: 10px;
    font-size: 18px;
}

.card-container .btn {
    background-color: var(--bordo);
    padding: 15px 60px;
}

.card-container .btn:hover a {
    color:  var(--bordo, #550722);
}

.card-container .btn:hover {
    background-color: #fff; 
}

@media only screen and (max-width: 775px) {  
    .card-container,
    .small-card-container {
        position: absolute;
        top: 35%;
        right: 0px !important;
        transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        text-align: center;
        border-radius: 25px;
        max-width: 100vw !important;
        height: 375px;
    }

    .small-card-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 120px;
    }

    .card-container h2, .card-container p, .card-container .btn{
        margin: 30px;
        text-align: center; /* Center the text content */ 
    }


    .small-card-container h2, p {
        text-align: center;
        padding: 10px 15px;
    }


    .card-container h2,
    .small-card-container h2 {
        padding: 14px;
    }

    .small-card-container h2 {
        border-bottom: 1px solid  var(--bordo); 
    }

    .card-container p, 
    .small-card-container p {
        padding: 5px 50px;
        gap: 10px;
        font-size: 18px;
    }

    .card-container .btn {
        background-color: var(--bordo);
        padding: 15px 60px;
    }

    .card-container .btn:hover a {
        color:  var(--bordo, #550722);
    }

    .card-container .btn:hover {
        background-color: #fff; 
    }
}
/* Schedule Card */

.schedule-card {
    position: absolute;
    bottom: 20px;
    left: 50px;
    width: calc(100% - 100px); /* Adjusted width to account for padding */
    background-color: #fff;
    padding: 22.25px;
}
.schedule-card a {
    text-decoration: none;
    color: black;
}

.schedule-card h2 {
    color:  var(--bordo);
}
.schedule-card div {
    display: inline-block;
} 
.schedule-card p {
    text-align: left;
}
#specialpane {
    z-index: 999 !important;
}
.slick-list {
    min-width: 100% !important;
    min-height: 100% !important;
}

/* Join Our Newsletter + Login Page Card + Contact Us + About Us*/
.news-content-redone,
.login-content,
.contact-content,
.lot-form{
    display:flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    max-width: 600px; /* Adjusted width */    
    margin: 90px auto; /* Center the form */    
}

.news-content,
.login-content,
.contact-content,
.lot-form{
    display:flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    max-width: 600px; /* Adjusted width */    
    margin: 90px auto; /* Center the form */    
}

.contact-content{
    margin: 60px;
}



.news-content h2,
.login-content h2,
.contact-content p {
    margin-bottom: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid  var(--bordo); /* Added border bottom for separation */
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.25);
}



.news-content form,
.login-content form,    
.contact-content form {    
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
}

.contact-content form,
.contact-content p,
.contact-content h2 {
    text-align: left;
}

.news-content-redone input,
.login-content input,
.contact-content input,
.contact-content textarea,
.auc-controls input {
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box; /* Ensures the padding and border are included in the total width */
    align-self: center;
    margin: auto;
}

.news-content input,
.login-content input,
.contact-content input,
.contact-content select,
.contact-content textarea,
.auc-controls select,
.auc-controls input {
    width: calc(100% - 22px); /* Adjusted width to account for padding and border */
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box; /* Ensures the padding and border are included in the total width */
    align-self: center;
    margin: auto;
}


/* Style for focus state */
.news-content input:focus, 
.login-content input:focus,
.contact-content input:focus,
.contact-content textarea:focus,
.contact-content select:focus,
.auc-controls select:focus,
.auc-controls input:focus {
    border: 1px solid var(--bordo); /* Adjusted thickness and color */
}

.news-content input::placeholder, 
.login-content input::placeholder,
.contact-content input::placeholder,
.auc-content input::placeholder,
.contact-content textarea::placeholder,
.contact-content select,
.auc-controls select,
.lot-info-row select{
    color: var(--gray);
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4px;
}

/* Style for textarea specifically */
.contact-content textarea {
    resize: none; /* Disallow vertical resizing */
    min-height: 200px; /* Set a minimum height */
}



.news-content .btn, 
.login-content .btn,
.contact-content .btn {
    background-color: var(--bordo);
    padding: 15px 60px;
    margin-top: 30px; /* Added margin to separate the button from the inputs */
    margin-bottom: 30px ; /* Added margin to separate the button from the inputs */
    align-self: center; /* Center the button */
}


.news-content .btn:hover a,
.login-content .btn:hover a,
.contact-content .btn:hover a {
    color: var(--bordo);
}

.news-content .btn:hover,
.login-content .btn:hover,
.contact-content .btn:hover{
    background-color: #fff; 
}

/* Contact Us Address */

#address,
#phones,
#about {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-style: italic;
    font-weight: lighter;
    text-align: start;
    border-bottom: none; /* Added border bottom for separation */
    border-top: 1px solid var(--bordo);
    box-shadow: 0px -2px 2px -2px rgba(0, 0, 0, 0.25);
    white-space: pre-line;
    padding: 10px 15px;
    margin: 0;
}

#phones {   
    border-top: none;
    box-shadow: none;
    padding: 1px 15px;
}

#about {
    font-style: normal;
    font-weight: normal;
    border-top:  none;
    box-shadow: none;
    white-space: normal;
}


#login-form {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 30px 30px;
}

#register-form {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 30px 30px;
}



/* For Login Page - Remember Me and Forgot Password */

.remember-forgot {
    display: flex;    
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    white-space: nowrap;
}

.remember-forgot label {
    display: flex;
    position: relative;
    text-align: left;
    align-items: center;
    font-size: 14px; 
}

.remember-forgot input[type="checkbox"] {
    background: none;
    box-shadow: none;
    margin-right: 10px;
}

.forgot-password {
    text-decoration: none;
    color: var(--bordo); /* Adjust the color as needed */
    font-size: 12px; /* Adjust the font size as needed */
    font-style: italic;
}

.forgot-password:hover {
    text-decoration: underline;
}

/* Buyers/Sellers Links-Menu */

.links-menu {
    display:flex;
    flex-wrap: nowrap;
    text-align: start;
    gap: 10px;
    justify-content:space-evenly;
    padding: 15px 30px;
    background-color: var(--gray);    
}

.links-menu li {
    list-style: none;
}

.links-menu a {
    color: black;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}

.links-menu a:hover {
    text-decoration: underline;
    color: var(--beige);
}

.links-menu a.active {
    color: var(--beige);
}

.contact-content form.hidden {
    display: none;
}

/* Footer */

footer{
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.foot-links a{
    color: #000;
    height: 35px;
    justify-content: left;
    align-items: left;    
    font-weight: lighter;
    font-size: 14px;
    
}

.socials {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
    justify-content: right;
    align-items: center;
}

.socials img {
    height: 25px;
    width: 25px;
}

.socials a{
    cursor: pointer;
}

.copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: lighter;
    font-size: 12px;
    width: 100%;
    padding: 10px 0;
    background-color: #fff;
    color: var(--gray);
}



/* Current Auction */
.auc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
}

.view-form {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.view-form p {
    margin-top: 15px;   /* Adjust as needed for alignment between text and checkboxes */
    font-style: italic;
    color: var(--bordo);
    font-size: 12px;
    font-weight: bold;
}

.view-form label {
    display: flex;
    align-items: center;
    gap: 30px;
}

/* radiobuttons */

/* Style for the radio buttons */
.view-form input[type="radio"] {
    position: absolute;
    opacity: 0;
}

/* Style for the container of the radio buttons */
.view-form .radio-container {
    position: relative;
    cursor: pointer;
    margin-right: 20px; /* Adjust spacing between radio buttons */
}

/* Style for the circular shape */
.view-form label::before {
    content: '';
    display: inline-block;
    width: 15px; /* Adjust the size of the circular checkbox */
    height: 15px; /* Adjust the size of the circular checkbox */
    border: none; /* Set border to transparent */
    border-radius: 50%; /* Make it a circle */
    background-color: white; /* Background color of the circular checkbox */
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

/* Style for the checkmark when the checkbox is checked */
.view-form input[type="radio"]:checked + label::before {
    background-color: var(--bordo); 
}


/* auction content form */
/*
.auc-table-container,
.auc-cards-container
{
    max-height: 700px; 
    overflow-y: auto; 
}
*/

.auc-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    justify-content: space-around;
}


.auc-content {
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    border-top: 1px solid var(--bordo);
    margin: 40px;
}


.auc-controls,
.auc-table tr,
.auc-cards{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin: 20px;
    border-radius: 25px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.auc-controls a {
    text-align: left;
    white-space: pre;
}

/* Lot Table */

.auc-table {
    width: 100%;
    border-collapse: collapse;
}

.auc-content tr {
    justify-content:space-evenly;
}
.auc-table td,
.auc-cards{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content:space-around;
    color: var(--bordo);
    font-weight: bold;
}


.auc-cards {
    flex: 1 0 calc(25.33% - 20px); /* Adjust the percentage based on your needs */
    flex-wrap: wrap;
    max-width: calc(25.33% - 10px); /* Adjust the maximum width based on your needs */
}


.auc-table a,
.auc-cards a {
    text-decoration: none;
    color: var(--bordo);
}

.auc-table a:hover,
.auc-cards a:hover {
    text-decoration: underline;
    color: var(--beige);
}
.auc-table p,
.auc-cards p {
    font-weight: lighter;
    color: black;
    font-style: italic;
}


.auc-table img{
    width: 100px;
    height: auto;
    border-radius: 15px;
}

.auc-cards img {
    width: 100%; /* Make the image take 100% width of the card */
    height: auto; /* Maintain aspect ratio */
    border-radius: 15px;
}

.auc-cards p,
.auc-cards h2 {
    margin:0; /* Adjust the spacing between text elements as needed */
    padding:0;
    font-size: 14px;
    text-align: left;
}

.auc-form{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: auto;
    gap: 10px;
}


/* View toggle*/

.auc-container {
    display: none;
}

.hidden{
    display: none;
}
/* Lot Page */

.lot-container {
    margin: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    padding: 10px 15px;
    align-items: center;
}

.lot-container .btn,
.lot-info-row #bd-btn{
    background-color: var(--bordo);
}
.lot-container .btn:hover ,
.lot-info-row #bd-btn:hover {
    background-color: white;
}

.lot-container a:hover,
.lot-info-row #bd-btn a:hover {
    color: var(--bordo); 
}

.lot-container p{
    font-weight: bold;
    color: var(--bordo);
}

.header-right .foot-links{
    display: flex;
    flex-direction: row;
    padding:10px 15px;
    margin:30px;
    font-weight: bold;
}

/* Lot Form */

#lot-nav{
    margin-top:20px;
    box-shadow: none;
}

.lot-form {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width:100%;
    max-width: 1000px;
    text-align: left;
}

.lot-form header{
    margin:0;
    padding:0;
    box-shadow: none;    
}

.auc-info{
    display: flex;
    flex-direction: row;
}

.auc-info img{
    width: 400px;
    height: auto;
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.slick-dots {
    float: left !important;
    text-align: left !important;
}
/* .lot-info {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
}

.lot-info-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 15px;
}
.lot-info-row h2 {
    color: var(--bordo);
}


.lot-info h2, p{
    text-align: left;
    padding: 10px;
}

.lot-info p {
    line-height: 5px !important;
}
.lot-info h2{
    color: var(--bordo);
    font-size: 14px;
} */

.bid-history {
    display: flex;
    flex-wrap: wrap;
    
}

#bidding-his{
    padding: 10px 10px;
    width: 50%;
}


/* Separator */

.separator{
    margin:20px;
    padding:5px 5px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid black;  

}

.small-card-container p, h2{
    text-align: center;

}

.btn a:hover {
    cursor: pointer;
}
.header-right {
    max-height: 100px !important;
}
.header-right-full {
    max-height: 100vh !important;
} 
.ps-sidebar-container {
    position: fixed !important;
    background-color: rgba(255, 255, 255, 0.975) !important;
    min-width: 20vw !important;
    max-width: 20vw !important;
    min-height: 100vh !important;
    /* left: 0; */
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99999999999999 !important;
    border-right: 1.5px solid black;
}
@media only screen and (max-width: 750px) {
    .ps-sidebar-container {
        position: fixed !important;
        background-color: rgba(255, 255, 255, 0.975) !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
        min-height: 100vh !important;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 99999999999999 !important;
        border-right: 1.5px solid black;
    }
    
}
.ps-collapsed .ps-sidebar-container {
    display: none !important;
}

.ps-menu-root {
    float: left !important;
    margin: 22.25px !important;
}
.label-custom {
    float: left !important;
    padding-bottom: 3.25px;
    padding-top: 7.25px;
    text-align: left !important;
}
.hover:hover {
    cursor: pointer !important;
}
.centered {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 17.25px 0px;
}
@media only screen and (max-width: 775px) {
    .logo-container {
        display: none !important;
    }
}
.foot-links a:hover {
    cursor: pointer !important;
}
.min-height {
    min-height: 100vh;
}
.leader {
    font-size: 1.125rem;
}
#lot1 > header {
    z-index: 0 !important;
}
/* div > div > div.slide-pane__content > div > div.row.g-0.align-items-center.pb-4 > div > ul > li.page-item.active > a {
    background-color: transparent !important;
    color: blue !important;
    border: none;
} */

.react-confirm-alert-body {
    min-width: 75vw !important;
}
html, body { 
    overflow-x: hidden !important;
}
#fixside > div.position-bottom > ul > li.page-item.active .page-link {
    background-color: white !important;
    border: 0.25px solid lightgrey;
    color: #0d6efd;
}
._loading_overlay_wrapper {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    z-index: 99999999 !important;
    background-color: rgba(0, 0, 0, 0.525) !important;
    justify-content: center !important;
    align-items: center !important;
}
#shadow-host-companion {
    display: none !important;
}
.map {
    z-index: 0 !important;
}
.btn-brown {
    background: linear-gradient(96.86deg, #B95A3F 1.23%, #91442E 100%) !important;
    border: 1.5px solid white;
    border-radius: 5px;
}
.btn-blue {
    background: #2C849A !important;
    border: 1.5px solid white;
    border-radius: 5px;
}
.text-left {
    text-align: left !important;
}
.boxshadow {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
}
.spacer {
    height: 62.25px;
    min-height: 62.25px;
}
.btn-white {
    background-color: white !important;
    color: black !important;
    border-radius: 8.25px !important;
    border: 1px solid #000000 !important;
    -webkit-box-shadow: 7px 7px 5px 1px rgba(217,217,217,1);
    -moz-box-shadow: 7px 7px 5px 1px rgba(217,217,217,1);
    box-shadow: 7px 7px 5px 1px rgba(217,217,217,1);
}

p {
    font-size: 20px !important;
}
button {
    font-family: 'Poppins', sans-serif !important;
}
.react-responsive-modal-modal {
    border-radius: 17.75px !important;
    height: auto !important;
}

.centered {
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
}
#formatlefttext {
    text-align: left !important;
    float: left !important;
}
#lot1 > div.row > div.col-md-5.col-lg-5.col-xl-5.col-sm-12 > div > div {
    z-index: 0 !important;
}
div > div.rcb-chat-window > div.rcb-chat-header-container > div:nth-child(1) > div:nth-child(2) { 
    display: none !important;
}