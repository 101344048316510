.share-buttons {
    position: absolute;
    right: 250px;
    top: 32.25px;
}   

@media only screen and (max-width: 775px) {
    .share-buttons {
        position: absolute;
        right: 30px;
        top: 150px;
    }   
}
.share-buttons button img {
    border: none !important;
}