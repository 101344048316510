.estate .primary-image {
    border: 1px solid #e0eded;
    padding: 5px;
}

.estate .thumbnail-images {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
}

.estate .thumbnail-images a {
    display: table-cell;
    width: 100px;
    padding: 5px;
    border: 1px solid #e0eded;
    border-top: 0;
}
.theater img {
    max-width: 100% !important;
}
.estate .thumbnail-images a img {
    width: 100%;
}

.estate .product-info h3 {
    font-weight: 500;
    font-size: 18px;
}

.product-info h3 {
    margin-bottom: 10px;
}

.wp-block.property.list {
    border: 1px solid #e0eded;
}

.wp-block {
    margin: 0 0 15px 0;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    position: relative;
    cursor: default;
    border-radius: 2px;
}

.no-border {
    border: 0 !important;
}

.wp-block.property.list .wp-block-content .content-title {
    font-size: 20px;
    color: #3498db;
    margin-bottom: 5px;
}

.wp-block.property.list .wp-block-content .description {
    padding-bottom: 10px;
    border-bottom: 1px solid #e0eded;
}

.mb-15 {
    margin-bottom: 15px;
}

.wp-block.property.list .wp-block-content .price {
    font-size: 22px;
    color: green;
    font-weight: 600;
}

.wp-block.property.list .wp-block-content .period {
    margin-left: 5px;
    color: #999;
}

.wp-block.property.list .wp-block-content .capacity {
    font-size: 16px;
    color: #999;
}

.mt-15 {
    margin-top: 15px;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info {
    display: table;
    width: 100%;
    border: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer ul.aux-info {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    background: #fcfcfc;
    border-top: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li {
    padding: 10px 10px;
    font-size: 11px;
    text-align: center;
}

.wp-block.property.list .wp-block-footer ul.aux-info li {
    display: table-cell;
    padding: 10px 15px;
    vertical-align: middle;
    border-right: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li i {
    display: block;
}

.wp-block.property.list .wp-block-footer ul.aux-info li i {
    font-size: 18px;
    margin-right: 8px;
    color: #333;
}

.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    float: left;
    padding: 0 1px;
    cursor: pointer;
}

p {
    line-height: 22px;
    margin-bottom: 10px;
}

ol, ul {
    list-style: none;
}

.tabs-framed {
    border: 1px solid #e0eded;
}

.tabs li a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding: 12px 25px;
    margin: 0 -1px;
    border-bottom: 3px solid transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.boxed {
    background: #fff;
    border: 1px solid #e0eded;
    position: relative;
}

.tabs {
    border-bottom: 1px solid #e0eded;
}

.tabs li {
    float: left;
    border-right: 1px solid #e0eded;
    margin-bottom: -1px;
}

.tab-body {
    padding: 15px;
}             
@media only screen and (max-width: 775px) {
    .hideonmobile {
        display: none !important;
    }
}   
@media only screen and (min-width: 776px) {
    .hideondesktop {
        display: none !important;
    }
}