.thumb-lg {
    height: 88px;
    width: 88px;
}
.profile-user-box {
    position: relative;
    border-radius: 5px
}
.bg-custom {
    background-color: #000000!important;
}
.profile-user-box {
    position: relative;
    border-radius: 5px;
}

.card-box {
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 30px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
}
.inbox-widget .inbox-item img {
    width: 40px;
}
.tilebox-one {
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    min-height: 200px;
    box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
}
.card-custom-box-shadow {
    box-shadow: 10px 10px 5px 0px rgba(161, 161, 161, 0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(161, 161, 161, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.inbox-widget .inbox-item {
    border-bottom: 1px solid #f3f6f8;
    overflow: hidden;
    padding: 10px 0;
    position: relative
}

.inbox-widget .inbox-item .inbox-item-img {
    display: block;
    float: left;
    margin-right: 15px;
    width: 40px
}

.inbox-widget .inbox-item img {
    width: 40px
}

.inbox-widget .inbox-item .inbox-item-author {
    color: #313a46;
    display: block;
    margin: 0
}

.inbox-widget .inbox-item .inbox-item-text {
    color: #98a6ad;
    display: block;
    font-size: 14px;
    margin: 0
}

.inbox-widget .inbox-item .inbox-item-date {
    color: #98a6ad;
    font-size: 11px;
    position: absolute;
    right: 7px;
    top: 12px
}

.comment-list .comment-box-item {
    position: relative
}

.comment-list .comment-box-item .commnet-item-date {
    color: #98a6ad;
    font-size: 11px;
    position: absolute;
    right: 7px;
    top: 2px
}

.comment-list .comment-box-item .commnet-item-msg {
    color: #313a46;
    display: block;
    margin: 10px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px
}

.comment-list .comment-box-item .commnet-item-user {
    color: #98a6ad;
    display: block;
    font-size: 14px;
    margin: 0
}

.comment-list a+a {
    margin-top: 15px;
    display: block
}

.ribbon-box .ribbon-primary {
    background: #2d7bf4;
}

.ribbon-box .ribbon {
    position: relative;
    float: left;
    clear: both;
    padding: 5px 12px 5px 12px;
    margin-left: -30px;
    margin-bottom: 15px;
    font-family: Rubik,sans-serif;
    -webkit-box-shadow: 2px 5px 10px rgba(49,58,70,.15);
    -o-box-shadow: 2px 5px 10px rgba(49,58,70,.15);
    box-shadow: 2px 5px 10px rgba(49,58,70,.15);
    color: #fff;
    font-size: 13px;
}
.text-custom {
    color: #02c0ce!important;
}

.badge-custom {
    background: #02c0ce;
    color: #fff;
}
.badge {
    font-family: Rubik,sans-serif;
    -webkit-box-shadow: 0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02);
    box-shadow: 0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02);
    padding: .35em .5em;
    font-weight: 500;
}
.text-muted {
    color: #98a6ad!important;
}
#custombtnbtndates {
    padding: 10.25px !important;
    border-radius: 25px;
    color: white !important;
    background-color: rgb(112, 105, 244) !important;
    float: left;
    border: 1px solid white !important;
}
#custombtnbtn {
    padding: 10.25px !important;
    border-radius: 25px;
    color: white !important;
    background-color: rgb(3, 167, 3) !important;
    float: left;
    border: 1px solid white !important;
}
.font-13 {
    font-size: 13px!important;
}
.content-custom {
    background-color: rgba(207, 207, 207, 0.199);
    padding-top: 22.25px;
}
.order-card {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}
.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}
.card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 25px;
}

.card .card-header h5 {
    margin-bottom: 0;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.4;
}

.card .card-header+.card-block, .card .card-header+.card-block-big {
    padding-top: 0;
}
.user-card {
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 0.75);
}
.user-card .card-block {
    text-align: center;
}
.panel-body {
    max-height: 85vh !important;
    overflow-y: scroll;
}
.card .card-block {
    padding: 25px;
}
.modalimage {
    max-width: 90vw;
    max-height: 90vh;
}
.user-image img {
    max-width: 100%;
    max-height: 150px;
    cursor: pointer;
}
.f-w-600 {
    font-weight: 600;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-15 {
    margin-top: 15px;
}

.card .card-block p {
    line-height: 1.4;
}

.text-muted {
    color: #919aa3!important;
}

.user-card .card-block .activity-leval li.active {
    background-color: #2ed8b6;
}

.user-card .card-block .activity-leval li {
    display: inline-block;
    width: 15%;
    height: 4px;
    margin: 0 3px;
    background-color: #ccc;
}

.user-card .card-block .counter-block {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}

.m-t-10 {
    margin-top: 10px;
}

.p-20 {
    padding: 20px;
}

.user-card .card-block .user-social-link i {
    font-size: 30px;
}

.text-facebook {
    color: #3B5997;
}

.text-twitter {
    color: #42C0FB;
}

.text-dribbble {
    color: #EC4A89;
}
.pagination-container {
    align-items: left;
    display: flex;
    flex-direction: flex-row;
    justify-content: center;
    list-style: none;
    gap: 16px;
    padding: 0;
}

.pagination-page {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 0.875rem;
    line-height: 1.25rem; 
    font-weight: 700; 
    cursor: pointer;
}

.pagination-active > a {
    font-weight: bolder;
}

.pagination-next-prev {
    display: flex;
    padding: 0.75rem; 
    justify-content: center; 
    align-items: center; 
    font-size: 0.875rem;
    line-height: 1.25rem; 
    cursor: pointer;
}

.pagination-break {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 0.875rem;
    line-height: 1.25rem; 
    cursor: pointer; 
}