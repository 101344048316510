.lotimagefitted {
    object-fit: contain !important;
}
.image-slider-custom-outter {
    object-fit: contain !important;
    min-height: 325px; 
    height: 325px; 
    max-height: 325px; 
    min-width: 100%; 
    width: 100%; 
    max-width: 100%; 
}
.lotimagefittedlargertwo {
    min-height: 325px; 
    height: 325px; 
    max-height: 325px; 
    min-width: 100%; 
    width: 100%; 
    padding: 15px;
    object-fit: contain !important;
    max-width: 100%; 
}
.image-gallery-slide-wrapper {
    z-index: 0 !important;
}
#bidding-his {  
    width: 100% !important;
    text-align: center !important;
    max-width: 100%;
    min-width: calc(100% + 22.5px);
    margin: 0px;
    margin-left: -10px;
    margin-top: 15px;
    padding: 0px;
    -webkit-box-shadow: 10px 5px 5px 0px rgb(231, 231, 231);
    -moz-box-shadow: 5px 5px 5px 0px rgb(231, 231, 231);
    box-shadow: 5px 5px 5px 0px rgb(231, 231, 231);
}
#proxybidinput {
    -webkit-box-shadow: 10px 5px 5px 0px rgb(231, 231, 231);
    -moz-box-shadow: 5px 5px 5px 0px rgb(231, 231, 231);
    box-shadow: 5px 5px 5px 0px rgb(231, 231, 231);
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px;
}
.markdownstyle {
    /* flex-wrap: nowrap !important;
    text-align: left !important;
    margin-top: 32.25 !important; */
}
.select-search-select, .select-search-row, .select-search-container .--select-search-background {
    background-color: white !important;
}
.lotimagefittedlarger {
    object-fit: contain !important;
    min-height: 350px; 
    height: 350px; 
    max-height: 350px; 
    min-width: 100%; 
    width: 100%; 
    padding: 15px;
    max-width: 100%; 
    /* background-color: rgb(237, 237, 237) !important; */
}
.lotimagefittedlarger img {
    object-fit: contain !important;
    min-height: 300px; 
    height: 300px; 
    max-height: 300px; 
    width: 100%; 
    padding: 15px;
}
.project-list-table {
    border-collapse: separate;
    border-spacing: 0 12px
}

.project-list-table tr {
    background-color: #fff
}

.table-nowrap td, .table-nowrap th {
    white-space: nowrap;
}
.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0;
}
.table>:not(caption)>*>* {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.avatar-sm {
    height: 2rem;
    width: 2rem;
}
.rounded-circle {
    border-radius: 50%!important;
}
.me-2 {
    margin-right: 0.5rem!important;
}
img, svg {
    vertical-align: middle;
}

a {
    color: #3b76e1;
    text-decoration: none;
}

.badge-soft-danger {
    color: #f56e6e !important;
    background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
    color: #63ad6f !important;
    background-color: rgba(99,173,111,.1);
}

.badge-soft-primary {
    color: #3b76e1 !important;
    background-color: rgba(59,118,225,.1);
}

.badge-soft-info {
    color: #57c9eb !important;
    background-color: rgba(87,201,235,.1);
}

.avatar-title {
    align-items: center;
    background-color: #3b76e1;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.bg-soft-primary {
    background-color: rgba(59,118,225,.25)!important;
}
.estate .primary-image {
    border: 1px solid #e0eded;
    padding: 5px;
}

.estate .thumbnail-images {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
}

.estate .thumbnail-images a {
    display: table-cell;
    width: 100px;
    padding: 5px;
    border: 1px solid #e0eded;
    border-top: 0;
}

.estate .thumbnail-images a img {
    width: 100%;
}

.estate .product-info h3 {
    font-weight: 500;
    font-size: 18px;
}

.product-info h3 {
    margin-bottom: 10px;
}

.wp-block.property.list {
    border: 1px solid #e0eded;
}

.wp-block {
    margin: 0 0 15px 0;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    position: relative;
    cursor: default;
    border-radius: 2px;
}

.no-border {
    border: 0 !important;
}

.wp-block.property.list .wp-block-content .content-title {
    font-size: 20px;
    color: #3498db;
    margin-bottom: 5px;
}

.wp-block.property.list .wp-block-content .description {
    padding-bottom: 10px;
    border-bottom: 1px solid #e0eded;
}

.mb-15 {
    margin-bottom: 15px;
}

.wp-block.property.list .wp-block-content .price {
    font-size: 22px;
    color: #9cd70e;
    font-weight: 600;
}

.wp-block.property.list .wp-block-content .period {
    margin-left: 5px;
    color: #999;
}

.wp-block.property.list .wp-block-content .capacity {
    font-size: 16px;
    color: #999;
}

.mt-15 {
    margin-top: 15px;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info {
    display: table;
    width: 100%;
    border: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer ul.aux-info {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    background: #fcfcfc;
    border-top: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li {
    padding: 10px 10px;
    font-size: 11px;
    text-align: center;
}

.wp-block.property.list .wp-block-footer ul.aux-info li {
    display: table-cell;
    padding: 10px 15px;
    vertical-align: middle;
    border-right: 1px solid #e0eded;
}

.wp-block.property.list .wp-block-footer.style2 ul.aux-info li i {
    display: block;
}

.wp-block.property.list .wp-block-footer ul.aux-info li i {
    font-size: 18px;
    margin-right: 8px;
    color: #333;
}

.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    float: left;
    padding: 0 1px;
    cursor: pointer;
}

p {
    line-height: 22px;
    margin-bottom: 10px;
}

ol, ul {
    list-style: none;
}

.tabs-framed {
    border: 1px solid #e0eded;
}

.tabs li a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding: 12px 25px;
    margin: 0 -1px;
    border-bottom: 3px solid transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.boxed {
    background: #fff;
    border: 1px solid #e0eded;
    position: relative;
}

.tabs {
    border-bottom: 1px solid #e0eded;
}

.tabs li {
    float: left;
    border-right: 1px solid #e0eded;
    margin-bottom: -1px;
}

.tab-body {
    padding: 15px;
}     
.heavy-underline {
    font-weight: bold;
    text-decoration-line: underline;
    color: darkblue;
}
.ullist {
    list-style-type: circle;
}
.ullist-two {
    list-style-type: decimal;
}
.image-gallery-thumbnails-container {
    display: flex !important;
    /* flex-direction: row !important; */
}
.react-responsive-modal-modal {
    background-color: rgba(0, 0, 0, 0.65) !important;
    min-width: 100vw !important;
    min-height: 100vh !important;
    position: fixed !important;
    left: -20px !important;
    right: 0px !important;
    top: -20px !important;
    border-radius: 0px !important;
    bottom: 0px !important;
}
@media only screen and (max-width: 750px) {
    .react-responsive-modal-modal {
        background-color: black !important;
        position: fixed !important;
        left: 45% !important;
        top: 47.5% !important;
        transform: translate(-50%, -50%) !important; /* Centering */
        width: auto !important;
        height: auto !important;
        max-width: 90vw !important;
        max-height: 90vh !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0px !important;
    }
}
.error-page {
    text-align: center;
	background: #fff;
	border-top: 1px solid #eee;
    overflow-y: scroll;
    /* overflow-y: scroll !important; */
}
.error-page .error-inner {
	display: inline-block;
}
.lineheight {
    line-height: normal !important;
    text-align: left !important;
    margin-top: -17.5px !important;
}
#lot1 > div.row > div.col-md-8.col-lg-8.col-xl-8.col-sm-12 > div > div > div.col-md-7.col-lg-7.col-sm-12.col-xs-12 > p:nth-child(4) > div > span > span {
    text-align: left !important;
    float: left !important;
    text-wrap: pretty !important;
}
.lot-container-two p {
    margin: 20px;
    display: flex;
    flex-direction: row;
    color: #800020;
    font-weight: 450;
    margin-left: 5px;
    padding: 10px 15px;
    align-items: center;
}
@media only screen and (max-width: 775px) {
    #customheadedended {
        font-size: 72.25px !important;
        text-shadow: 3px 5px 2px #c5c5c533;
        color: #800020;
        font-weight: 700;
    }
}
.error-page .error-inner h1 {
	font-size: 3.5rem;
	text-shadow: 3px 5px 2px #c5c5c533;
	color: #800020;
	font-weight: 700;
}
.specialtitle {
    font-size: 24px !important;
    line-height: 45px;
    text-align: left;
    left: -30px !important;
    justify-self: center !important;
    align-items: center !important;
    align-content: center !important;
    display: flex !important;
    top: -32.5px;
    position: relative;
}
.error-page .error-inner h1 span {
	display: block;
	font-size: 25px;
	color: #333;
	font-weight: 600;
	text-shadow: none;
    max-width: 90vw;
}
.image-increments {
    border: 1.35px solid black;
    margin-bottom: 42.25px;
    margin-top: 20px;
    padding-top: 10px;
}
.error-page .error-inner p {
	padding: 20px 15px;
}
.error-page .search-form {
	width: 100%;
	position: relative;
}
.error-page .search-form input {
	width: 400px;
	height: 50px;
	padding: 0px 78px 0 30px;
	border: none;
	background: #f6f6f6;
	border-radius: 5px;
	display: inline-block;
	margin-right: 10px;
	font-weight:400;
	font-size:14px;
}
.error-page .search-form input:hover{
	padding-left:35px;
}
.error-page .search-form .btn {
	width: 80px;
	height: 50px;
	border-radius: 5px;
	cursor: pointer;
	background: #006DFE;
	display: inline-block;
	position: relative;
	top: -2px;
}
.error-page .search-form .btn i{
	font-size:16px;
}
.error-page .search-form .btn:hover{
	background:#333;
}
.show-more-less-clickable {
    color: blue !important;
    font-weight: 450;
}
.show-more-less-clickable:hover {
    cursor: pointer;
}
.magnifier-image {
    object-fit: contain !important;
}
#lot1 > div.row > div.col-md-8.col-lg-8.col-xl-8.col-sm-12 > div > p:nth-child(5) {
    line-height: 1.45 !important;
}
.magnifier-image {
    object-fit: contain !important;
}
.cover-background {
    position: relative !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.p-0 {
    padding: 0!important;
}
section {
    padding: 120px 0;
    overflow: hidden;
    background: #fff;
}

.error-page {
    background-color: #BABABA4A;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(234,234,235,0.2);
    padding: 80px 20px;
}
.text-center {
    text-align: center!important;
}
.error-page h1 {
    font-size: 200px;
    line-height: 1;
    font-weight: 600;
}
.text-secondary {
    color: #15395A !important;
}
.mb-4 {
    margin-bottom: 1.5rem!important;
}
.number-box {
    /* width: 50px; */
    max-width: 60%;
    height: 50px;
    background-color: #800020;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    font-size: 24px;
    font-weight: bold;
}
.iconed-arrow {
    max-width: 80px;
    max-height: 70px;
}
.markdownstyle p {
    text-align: left !important;
    /* float: left !important; */
    margin-top: 15px;
}
.single_advisor_profile {
    position: relative;
    margin-bottom: 50px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    z-index: 1;
    border-radius: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
}
#customrowline {
    /* -webkit-box-shadow: 10px 10px 5px 0px rgba(194,194,194,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(194,194,194,1);
    box-shadow: 10px 10px 5px 0px rgba(194,194,194,1); */
}
.single_advisor_profile .advisor_thumb {
    position: relative;
    z-index: 1;
    border-radius: 15px 15px 0 0;
    margin: 0 auto;
    padding: 30px 30px 0 30px;
    background-color: white;
    border: 1px solid lightgrey;
    overflow: hidden;
}
.single_advisor_profile .advisor_thumb::after {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    width: 150%;
    height: 80px;
    bottom: -45px;
    left: -25%;
    content: "";
    background-color: #ffffff;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}
@media only screen and (max-width: 575px) {
    .single_advisor_profile .advisor_thumb::after {
        height: 160px;
        bottom: -90px;
    }
}
.single_advisor_profile .advisor_thumb .social-info {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
    right: 30px;
    text-align: right;
}
.single_advisor_profile .advisor_thumb .social-info a {
    font-size: 14px;
    color: #020710;
    padding: 0 5px;
}
.single_advisor_profile .advisor_thumb .social-info a:hover,
.single_advisor_profile .advisor_thumb .social-info a:focus {
    color: #800020;
}
.single_advisor_profile .advisor_thumb .social-info a:last-child {
    padding-right: 0;
}
.single_advisor_profile .single_advisor_details_info {
    position: relative;
    z-index: 1;
    padding: 30px;
    text-align: right;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 0 0 15px 15px;
    background-color: #ffffff;
}
.single_advisor_profile .single_advisor_details_info::after {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 3px;
    background-color: #800020;
    content: "";
    top: 12px;
    right: 30px;
}
.single_advisor_profile .single_advisor_details_info h6 {
    margin-bottom: 0.25rem;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_advisor_profile .single_advisor_details_info h6 {
        font-size: 14px;
    }
}
.single_advisor_profile .single_advisor_details_info p {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 0;
    font-size: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_advisor_profile .single_advisor_details_info p {
        font-size: 12px;
    }
}
.single_advisor_profile:hover .advisor_thumb::after,
.single_advisor_profile:focus .advisor_thumb::after {
    background-color: #0000001d;
    border: 1.50px solid black;
}
.single_advisor_profile:hover .advisor_thumb .social-info a,
.single_advisor_profile:focus .advisor_thumb .social-info a {
    color: #000;
}
.single_advisor_profile:hover .advisor_thumb .social-info a:hover,
.single_advisor_profile:hover .advisor_thumb .social-info a:focus,
.single_advisor_profile:focus .advisor_thumb .social-info a:hover,
.single_advisor_profile:focus .advisor_thumb .social-info a:focus {
    color: #000;
}
.single_advisor_profile:hover .single_advisor_details_info,
.single_advisor_profile:focus .single_advisor_details_info {
    background-color: #0000001d;
}
.single_advisor_profile:hover .single_advisor_details_info::after,
.single_advisor_profile:focus .single_advisor_details_info::after {
    background-color: #000;
}
.single_advisor_profile:hover .single_advisor_details_info h6,
.single_advisor_profile:focus .single_advisor_details_info h6 {
    color: #000;
}
.single_advisor_profile:hover .single_advisor_details_info p,
.single_advisor_profile:focus .single_advisor_details_info p {
    color: #000;
}
@media only screen and (max-width: 775px) {
    .error-inner {
        max-width: 100vw !important;
        margin-left: -30px;
        width: 100vw !important;
    }
}
.auction-card {
    /* background-color: #e8f3f6; Light blue background */
    padding: 2rem;
    margin: 0 auto;
    text-align: center;
    border-radius: 8px;
    font-family: Arial, sans-serif;
}
.auction-title {
    font-size: 1.5rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.auction-subtitle {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: -10px;
    text-align: left;
}
.whiteline {
    border: 10px;
    border-bottom: 2.75px solid #000 !important;
    width: 100% !important;
}
.titleinside {
    text-align: left;
    margin-left: 0px;
    font-size: 38px !important;
}
.auction-estimate {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: -10px;
    text-align: left;
}

.auction-timer {
    font-size: 20px;
    color: #666;
    margin-bottom: 1.5rem;
    text-align: left;
}

.bid-button {
    background-color: #2c849a;
    color: white;
    font-size: 1rem;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 1rem;
}

.bid-button:hover {
    background-color: #246b7c;
}

.auction-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.action-button {
    background-color: #f0f4f7;
    color: #2c849a;
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
    border: 1px solid #c8d9df;
    border-radius: 5px;
    cursor: pointer;
}

.action-button:hover {
    background-color: #e0ebef;
}  
.gallery-container {
    position: relative;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}
#resetbtn {
    background-color: transparent; /* Transparent background */
    color: white; /* White text */
    border: 2px solid #fff; /* White border */
    padding: 2.5px 10px; /* Padding for better size */
    font-size: 16px; /* Adjust text size */
    font-weight: bold;
    border-radius: 8px; /* Rounded edges */
    cursor: pointer; /* Pointer cursor */
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 10px;
}

#resetbtn:hover {
    background-color: #fff; /* White background on hover */
    color: black !important; /* Text turns black */
    transform: scale(1.05); /* Slightly increase size */
    fill: black !important;
}

#resetbtn:active {
    transform: scale(0.95); /* Slightly shrink on click */
}

#resetbtn2 {
    background-color: transparent; /* Transparent background */
    color: white; /* White text */
    border: 2px solid white; /* White border */
    padding: 2.5px 10px; /* Padding for better size */
    font-size: 16px; /* Adjust text size */
    font-weight: bold;
    border-radius: 8px; /* Rounded edges */
    cursor: pointer; /* Pointer cursor */
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 10px;
}
.fifth-step:hover {
    cursor: pointer;
}

body > div:nth-child(12) > div > div.react-responsive-modal-container.react-responsive-modal-containerCenter > div > div > div > div > div.image-gallery-slide-wrapper.image-gallery-thumbnails-left > button.image-gallery-icon.image-gallery-fullscreen-button > svg {
    display: none !important;
}
#resetbtn2:hover {
    background-color: black; /* White background on hover */
    color: white !important; /* Text turns white */
    transform: scale(1.05); /* Slightly increase size */
}

#resetbtn2:active {
    transform: scale(0.95); /* Slightly shrink on click */
}

.gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
    /* height: 500px; */
    overflow: hidden;
}
.react-responsive-modal-closeButton {
    background-color: white;
    border-radius: 6px;
    margin-top: 42.50px;
    z-index: 9999999999 !important;
}
.top-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    pointer-events: none; /* Ensures it doesn’t interfere with clicks */
    z-index: 9999; /* Keeps it on top */
}
@media only screen and (min-width: 801px) {
    /* .react-responsive-modal-closeButton {
        background-color: white;
        border-radius: 6px;
        margin-right: 25px !important;
        margin-top: 25px !important;
    } */
    
    .react-responsive-modal-closeButton {
        background-color: white; /* White text */
        border: 2px solid white; /* White border */
        padding: 2.5px 10px; /* Padding for better size */
        font-size: 16px; /* Adjust text size */
        font-weight: bold;
        margin-right: 25px;
        border-radius: 8px; /* Rounded edges */
        cursor: pointer; /* Pointer cursor */
        transition: all 0.3s ease-in-out;
        text-transform: uppercase;
        letter-spacing: 1px;
        /* margin-right: 25px !important; */
        margin-top: 22.5px !important;
        margin-left: 10px;
    }
    
    .react-responsive-modal-closeButton:hover {
        background-color: white; /* White background on hover */
        color: black; /* Text turns black */
        transform: scale(1.05); /* Slightly increase size */
    }
    
    .react-responsive-modal-closeButton:active {
        transform: scale(0.95); /* Slightly shrink on click */
    }
}

.swiper {
    width: 100%;
    height: 100%;
    background: #000;
}

.swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
}

.parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

.swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
}

.swiper-slide .subtitle {
    font-size: 21px;
}

.swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
}
.coverimg {
    object-fit: cover !important;
}
#whitebutton {
    background-color: white !important;
}
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure the slide takes full height */
    width: 100%;
}

.image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the image is fully visible without cropping */
}
.gallery-image {
    max-width: 85vw !important;
    max-height: 90vh !important;
    min-width: 85vw !important;
    min-height: 90vh !important;
    border-radius: 10px;
    z-index: 999999999999999999999999999;
    animation: fadeIn 0.85s ease-in-out forwards;
    object-fit: contain !important;
}
.image-gallery-image, .image-gallery-thumbnail-image {
    border-radius: 10px !important;
    z-index: 999999999999999999999999999999999999999999999999999999999999999999;
}
/* .image-gallery-svg {
    display: none !important;
} */
body > div:nth-child(15) > div > div.react-responsive-modal-container.react-responsive-modal-containerCenter > div > div > div > div.gallery > button.nav-button.right, body > div:nth-child(15) > div > div.react-responsive-modal-container.react-responsive-modal-containerCenter > div > div > div > div.gallery > button.nav-button.left {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}
.nav-button {
    position: absolute;
    top: 50%;
    border-radius: 15px;
    transform: translateY(-50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.00000000001);
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 24px;
    z-index: 10;
}
@media only screen and (min-width: 801px) {
    .nav-button {
        position: absolute;
        top: 50%;
        border-radius: 15px;
        transform: translateY(-50%);
        color: white;
        background-color: rgba(0, 0, 0, 0.00000000001);
        border: none;
        padding: 10px 125px;
        cursor: pointer;
        font-size: 24px;
        z-index: 10;
    }
}
.left {
    left: 10px;
}
.zoom-controls {
    position: absolute;
    top: -13.5px;
    right: 65px;
    /* background-color: rgba(0, 0, 0, 0.25) !important; */
    padding: 12.25px 15.5px 12.25px 7.5px;
    border-radius:  12.25px;
    z-index: 9999999999 !important;
    /* border: 1px solid white; */
}
.right {
    right: 10px;
}
#boxshadow {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    box-shadow: 10px 10px 5px 0px rgba(230,230,230,1);
    border-radius: 12.25px;
}
.thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    left: 0;
    top: 0;
    max-height: 70vh;
    overflow-y: auto;
    flex-direction: column;
}

.thumbnail {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 5px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
}

.thumbnail.active {
    border-color: cyan;
}

.fullscreen-button {
    margin-top: 10px;
    padding: 8px 15px;
    font-size: 16px;
    cursor: pointer;
    background: #333;
    color: white;
    border: none;
    border-radius: 5px;
}
.react-transform-wrapper:hover { 
    cursor: grab !important;
}

#lot1 > div.row > div.col-md-5.col-lg-5.col-xl-5.col-sm-12 > div > div > div > div.image-gallery-slide-wrapper.image-gallery-thumbnails-left > button.image-gallery-icon.image-gallery-left-nav > svg, #lot1 > div.row > div.col-md-5.col-lg-5.col-xl-5.col-sm-12 > div > div > div > div.image-gallery-slide-wrapper.image-gallery-thumbnails-left > button.image-gallery-icon.image-gallery-right-nav > svg {
    max-width: 50px !important;
    max-height: 50px !important;
}