.search-result-categories>li>a {
    color: #b6b6b6;
    font-weight: 400;
}

/* WRAPPERS */
#wrapper {
    width: 100%;
    overflow-x: hidden;
}
.wrapper {
    padding: 0 20px;
}
.wrapper-content {
    padding: 20px 10px 40px;
}
#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
    position: relative !important;
}
@media (min-width: 768px) {
    #page-wrapper {
    position: inherit;
    margin: 0 0 0 240px;
    min-height: 2002px;
    }
}

/* Payments */
.payment-card {
    background: #ffffff;
    padding: 20px;
    margin-bottom: 25px;
    border: 1px solid #e7eaec;
}
.payment-icon-big {
    font-size: 60px;
    color: #d1dade;
}
.payments-method.panel-group .panel + .panel {
    margin-top: -1px;
}
.payments-method .panel-heading {
    padding: 15px;
}
.payments-method .panel {
    border-radius: 0;
}
.payments-method .panel-heading h5 {
    margin-bottom: 5px;
}
.payments-method .panel-heading i {
    font-size: 26px;
}

.payment-icon-big {
    font-size: 60px !important;
    color: #d1dade;
}

.form-control,
.single-line {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
}
.text-navy {
    color: #1ab394;
}
.text-success {
    color: #1c84c6;
}
.text-warning {
    color: #f8ac59;
}
.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}
.ibox.collapsed .ibox-content {
    display: none;
}
.ibox.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}
.ibox.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}
.ibox:after,
.ibox:before {
    display: table;
}
.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 3px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 14px 15px 7px;
    min-height: 48px;
}
.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}
.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}
.text-danger {
    color: #ed5565;
}
.search-result-categories>li>a:hover {
    background-color: #ddd;
    color: #555;
}

.search-result-categories>li>a>.glyphicon {
    margin-right: 5px;
}

.search-result-categories>li>a>.badge {
    float: right;
}
.search-results-count-red {
    margin-top: 10px;
    font-size: 1.45rem;
    text-align: center;
    color: red;
    text-decoration-line: underline;
    font-weight: bold;
    height: 100% !important;
}
.search-results-count {
    margin-top: 10px;
    font-size: 1.45rem;
    text-align: center;
    color: green;
    text-decoration-line: underline;
    font-weight: bold;
    height: 100% !important;
}

.search-result-item {
    padding: 20px;
    height: 200px;
    background-color: #fff;
    border-radius: 4px;
}

.search-result-item:after,
.search-result-item:before {
    content: " ";
    display: table;
}

.search-result-item:after {
    clear: both;
}

.search-result-item .image-link {
    display: block;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

@media (min-width:768px) {
    .search-result-item .image-link {
        display: inline-block;
        margin: -20px 0 -20px -20px;
        float: left;
        width: 200px;
    }
}

@media (max-width:767px) {
    .search-result-item .image-link {
        max-height: 200px;
    }
}

.search-result-item .image {
    max-width: 100%;
}

.search-result-item .info {
    margin-top: 2px;
    font-size: 12px;
    color: #999;
}

.search-result-item .description {
    font-size: 13px;
}

.search-result-item+.search-result-item {
    margin-top: 20px;
}
#cardborder {
    border: 1px solid lightgrey;
}
@media (min-width:768px) {
    .search-result-item-body {
        margin-left: 200px;
    }
}

.search-result-item-heading {
    font-weight: 400;
}

.search-result-item-heading>a {
    color: #555;
}

@media (min-width:768px) {
    .search-result-item-heading {
        margin: 0;
    }
}
.resizeimage {
    object-fit: cover;
    max-height: 112.25px;
    border-radius: 10px;
    min-height: 112.25px;
    width: 100%;
}
.receipt-content .logo a:hover {
    text-decoration: none;
    color: #7793C4; 
}

.receipt-content .invoice-wrapper {
    background: #FFF;
    border: 1px solid #CDD3E2;
    box-shadow: 0px 0px 1px #CCC;
    padding: 40px 40px 125px;
    margin-top: 40px;
    border-radius: 4px; 
}

.receipt-content .invoice-wrapper .payment-details span {
    color: #A9B0BB;
    display: block; 
}
.receipt-content .invoice-wrapper .payment-details a {
    display: inline-block;
    margin-top: 5px; 
}

.receipt-content .invoice-wrapper .line-items .print a {
    display: inline-block;
    border: 1px solid #9CB5D6;
    padding: 13px 13px;
    border-radius: 5px;
    color: #708DC0;
    font-size: 13px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear; 
}

.receipt-content .invoice-wrapper .line-items .print a:hover {
    text-decoration: none;
    border-color: #333;
    color: #333; 
}

.receipt-content {
    background: #ECEEF4; 
    min-width: 100% !important;
    height: 100% !important;
}
@media (min-width: 1200px) {
    .receipt-content .container {width: 100%; } 
}

.receipt-content .logo {
    text-align: center;
    margin-top: 50px; 
}

.receipt-content .logo a {
    font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
    font-size: 36px;
    letter-spacing: .1px;
    color: #555;
    font-weight: 300;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear; 
}

.receipt-content .invoice-wrapper .intro {
    line-height: 25px;
    color: #444; 
}

.receipt-content .invoice-wrapper .payment-info {
    margin-top: 25px;
    padding-top: 15px; 
}

.receipt-content .invoice-wrapper .payment-info span {
    color: #A9B0BB; 
}

.receipt-content .invoice-wrapper .payment-info strong {
    display: block;
    color: #444;
    margin-top: 3px; 
}

@media (max-width: 767px) {
    .receipt-content .invoice-wrapper .payment-info .text-right {
    text-align: left;
    margin-top: 20px; } 
}
.receipt-content .invoice-wrapper .payment-details {
    border-top: 2px solid #EBECEE;
    margin-top: 30px;
    padding-top: 20px;
    line-height: 22px; 
}


@media (max-width: 767px) {
    .receipt-content .invoice-wrapper .payment-details .text-right {
    text-align: left;
    margin-top: 20px; } 
}
.receipt-content .invoice-wrapper .line-items {
    margin-top: 40px; 
}
.receipt-content .invoice-wrapper .line-items .headers {
    color: #A9B0BB;
    font-size: 13px;
    letter-spacing: .3px;
    border-bottom: 2px solid #EBECEE;
    padding-bottom: 4px; 
}
.receipt-content .invoice-wrapper .line-items .items {
    margin-top: 8px;
    border-bottom: 2px solid #EBECEE;
    padding-bottom: 8px; 
}
.receipt-content .invoice-wrapper .line-items .items .item {
    padding: 10px 0;
    color: #696969;
    font-size: 15px; 
}
@media (max-width: 767px) {
    .receipt-content .invoice-wrapper .line-items .items .item {
    font-size: 13px; } 
}
.receipt-content .invoice-wrapper .line-items .items .item .amount {
    letter-spacing: 0.1px;
    color: #84868A;
    font-size: 16px;
}
@media (max-width: 767px) {
    .receipt-content .invoice-wrapper .line-items .items .item .amount {
    font-size: 13px; } 
}

.receipt-content .invoice-wrapper .line-items .total {
    margin-top: 30px; 
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes {
    float: left;
    width: 40%;
    text-align: left;
    font-size: 13px;
    color: #7A7A7A;
    line-height: 20px; 
}

@media (max-width: 767px) {
    .receipt-content .invoice-wrapper .line-items .total .extra-notes {
    width: 100%;
    margin-bottom: 30px;
    float: none; } 
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes strong {
    display: block;
    margin-bottom: 5px;
    color: #454545; 
}

.receipt-content .invoice-wrapper .line-items .total .field {
    margin-bottom: 7px;
    font-size: 14px;
    color: #555; 
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500; 
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total span {
    color: #20A720;
    font-size: 16px; 
}

.receipt-content .invoice-wrapper .line-items .total .field span {
    display: inline-block;
    margin-left: 20px;
    min-width: 85px;
    color: #84868A;
    font-size: 15px; 
}

.receipt-content .invoice-wrapper .line-items .print {
    margin-top: 50px;
    text-align: center; 
}



.receipt-content .invoice-wrapper .line-items .print a i {
    margin-right: 3px;
    font-size: 14px; 
}

.receipt-content .footer {
    margin-top: 40px;
    margin-bottom: 110px;
    text-align: center;
    font-size: 12px;
    color: #969CAD; 
}                    

.post-editor,
.stream-post {
    padding: .75rem;
    background: #f7f7f9;
    border: 1px solid #eceeef;
    border-radius: .25rem;
    margin-bottom: 1.5rem
}

.profile-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (min-width:768px) {
    .profile-wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

body.menubar-top .profile-wrapper {
    border: 1px solid #eceeef
}

.profile-section-user {
    border-right: 1px solid #eceeef;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    min-width: 100% !important;
}

@media (min-width:768px) {
    .profile-section-user {
        -ms-flex-preferred-size: 34%;
        flex-basis: 34%
    }
}

@media (min-width:992px) {
    .profile-section-user {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%
    }
}

.profile-section-user table {
    margin-bottom: 0
}
.profile-section-user table td {
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.profile-section-user .img-gallery [class*=col] {
    padding-right: .25rem;
    padding-left: .25rem
}

.profile-section-user .img-gallery .row {
    margin-right: -.25rem;
    margin-left: -.25rem
}

.profile-section-user .img-gallery .g__item {
    margin-bottom: .5rem
}

.profile-cover-img img {
    width: 100%;
    min-width: 100%;
    height: 250px;
    object-fit: cover;
}

.profile-info-brief {
    position: relative;
    padding-top: 74px!important
}

.profile-info-brief .user-profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px)
}

.profile-section-main {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 5000px;
    flex: 0 1 5000px;
    overflow: hidden
}
.cover {
    object-fit: cover !important;
    min-width: 100%;
    min-height: 100%;
}
.user-list tbody td > img {
    position: relative;
	max-width: 50px;
	float: left;
	margin-right: 15px;
}
.user-list tbody td .user-link {
	display: block;
	font-size: 1.25em;
	padding-top: 3px;
	margin-left: 60px;
}
.user-list tbody td .user-subhead {
	font-size: 0.875em;
	font-style: italic;
}

/* TABLES */
.table {
    border-collapse: separate;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
	background-color: #eee;
}
.table thead > tr > th {
	border-bottom: 1px solid #C2C2C2;
	padding-bottom: 0;
}
.table tbody > tr > td {
	font-size: 0.875em;
	background: #f5f5f5;
	border-top: 10px solid #fff;
	vertical-align: middle;
	padding: 12px 8px;
}
.table tbody > tr > td:first-child,
.table thead > tr > th:first-child {
	padding-left: 20px;
}
.table thead > tr > th span {
	border-bottom: 2px solid #C2C2C2;
	display: inline-block;
	padding: 0 5px;
	padding-bottom: 5px;
	font-weight: normal;
}
.table thead > tr > th > a span {
	color: #344644;
}
.table thead > tr > th > a span:after {
	content: "\f0dc";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	margin-left: 5px;
	font-size: 0.75em;
}
.table thead > tr > th > a.asc span:after {
	content: "\f0dd";
}
.table thead > tr > th > a.desc span:after {
	content: "\f0de";
}
.table thead > tr > th > a:hover span {
	text-decoration: none;
	color: #2bb6a3;
	border-color: #2bb6a3;
}
.table.table-hover tbody > tr > td {
	-webkit-transition: background-color 0.15s ease-in-out 0s;
	transition: background-color 0.15s ease-in-out 0s;
}
.table tbody tr td .call-type {
	display: block;
	font-size: 0.75em;
	text-align: center;
}
.table tbody tr td .first-line {
	line-height: 1.5;
	font-weight: 400;
	font-size: 1.125em;
}
.table tbody tr td .first-line span {
	font-size: 0.875em;
	color: #969696;
	font-weight: 300;
}
.table tbody tr td .second-line {
	font-size: 0.875em;
	line-height: 1.2;
}
.table a.table-link {
	margin: 0 5px;
	font-size: 1.125em;
}
.table a.table-link:hover {
	text-decoration: none;
	color: #2aa493;
}
.table a.table-link.danger {
	color: #fe635f;
}
.table a.table-link.danger:hover {
	color: #dd504c;
}

.table-products tbody > tr > td {
	background: none;
	border: none;
	border-bottom: 1px solid #ebebeb;
	-webkit-transition: background-color 0.15s ease-in-out 0s;
	transition: background-color 0.15s ease-in-out 0s;
	position: relative;
}
.table-products tbody > tr:hover > td {
	text-decoration: none;
	background-color: #f6f6f6;
}
.table-products .name {
	display: block;
	font-weight: 600;
	padding-bottom: 7px;
}
.table-products .price {
	display: block;
	text-decoration: none;
	width: 50%;
	float: left;
	font-size: 0.875em;
}
.table-products .price > i {
	color: #8dc859;
}
.table-products .warranty {
	display: block;
	text-decoration: none;
	width: 50%;
	float: left;
	font-size: 0.875em;
}
.table-products .warranty > i {
	color: #f1c40f;
}
.table tbody > tr.table-line-fb > td {
	background-color: #9daccb;
	color: #262525;
}
.table tbody > tr.table-line-twitter > td {
	background-color: #9fccff;
	color: #262525;
}
.table tbody > tr.table-line-plus > td {
	background-color: #eea59c;
	color: #262525;
}
.table-stats .status-social-icon {
	font-size: 1.9em;
	vertical-align: bottom;
}
.table-stats .table-line-fb .status-social-icon {
	color: #556484;
}
.table-stats .table-line-twitter .status-social-icon {
	color: #5885b8;
}
.table-stats .table-line-plus .status-social-icon {
	color: #a75d54;
}
.minimumhhh {
    overflow-y: hidden;
    overflow-x: hidden;
}
@media only screen and (max-width: 775px) {
    #cardborder {
        border: 1px solid black;
        min-height: 60vh !important;
        overflow-y: scroll !important;
    }
}

.history-wrapper {
    position: relative;
    padding: 50px 0 50px;
}
.history-wrapper:after {
    content: "";
    width: 3px;
    height: 100%;
    background: #ededed;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
}
.history-wrapper .title-wrap {
    opacity: 0.2;
    padding: 100px 0 0 45px;
}
.history-wrapper .timeline-box {
    position: relative;
}
.one-of-two {
    width: 50%;
    float: left;
}
.history-wrapper .timeline-box:not(:last-child) {
    margin-bottom: 140px;
}
.timeline-box:nth-child(2n) {
    padding: 0 0 0 140px;
}
.history-wrapper .timeline-box:nth-child(2n) {
    margin-bottom: 140px;
}
.history-wrapper .timeline-box:nth-child(2n + 1) {
    margin-top: -24%;
    text-align: right;
}
.timeline-box:nth-child(2n + 1) {
    padding: 0 140px 0 0;
}
.year {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: 30%;
    color: #3b3b3b;
    transform: translateY(-50%);
    border: 1px solid #dbdbdb;
    line-height: 100px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}
.timeline-box:nth-child(2n) .year {
    left: 25px;
}
.timeline-box:nth-child(2n + 1) .year {
    right: 32px;
}
.year:before {
    content: "";
    width: 15px;
    height: 15px;
    border-left: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    background: #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -7.5px;
}
.timeline-box:nth-child(2n) .year:before {
    left: -8px;
    transform: rotate(45deg);
}
.timeline-box:nth-child(2n + 1) .year:before {
    right: -8px;
    transform: rotate(-135deg);
}
.timeline-box:after {
    content: "";
    width: 3px;
    height: 0;
    background: #ffa200;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    z-index: 1;
    transition: all 0.3s ease-in-out;
}
.timeline-box:nth-child(2n):after {
    left: -1.5px;
}
.timeline-box:nth-child(2n + 1):after {
    right: -1.5px;
}
.timeline-box:hover:after {
    height: 97px;
}
.timeline-box:before {
    content: "";
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 5px solid #ededed;
    position: absolute;
    background: #fff;
    z-index: 2;
    top: 30%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}
.timeline-box:nth-child(2n):before {
    left: -10.5px;
}
.timeline-box:nth-child(2n + 1):before {
    right: -10.5px;
}
.timeline-box:hover:before {
    border: 5px solid #ffa200;
}
@media screen and (max-width: 1199px) {
    .year {
        width: 90px;
        height: 90px;
        line-height: 90px;
        font-size: 22px;
    }
}
@media screen and (max-width: 991px) {
    .history-wrapper {
        padding: 30px 0 30px;
    }
    .history-wrapper .title-wrap {
        padding: 0;
        margin-bottom: 30px;
    }
    .title-wrap.one-of-two {
        width: 100%;
    }
    .history-wrapper:after {
        left: 0;
    }
    .history-wrapper .timeline-box:nth-child(2n) {
        padding: 0 0 0 140px;
    }
    .history-wrapper .timeline-box:not(:last-child) {
        margin-bottom: 40px;
    }
    .timeline-box.one-of-two,
    .timeline-box .img img {
        width: 100%;
    }
    .history-wrapper .timeline-box:nth-child(2n + 1) {
        margin-top: 0;
        text-align: left;
    }
    .timeline-box:nth-child(2n + 1) .year {
        left: 32px;
    }
    .timeline-box:nth-child(2n + 1):before {
        left: -10.5px;
    }
    .timeline-box:nth-child(2n + 1) .year:before {
        left: -8px;
        transform: rotate(45deg);
    }
    .timeline-box:nth-child(2n + 1):after {
        left: -1.5px;
    }
    .history-wrapper .timeline-box:nth-child(2n + 1) {
        margin-top: 0;
        text-align: left;
    }
    .timeline-box:nth-child(2n + 1) {
        padding: 0 0 0 140px;
    }
}
@media screen and (max-width: 767px) {
    .history-wrapper:after,
    .year,
    .timeline-box:before {
        display: none;
    }
    .history-wrapper {
        padding: 0;
    }
    .history-wrapper .timeline-box:nth-child(2n),
    .history-wrapper .timeline-box:nth-child(2n + 1) {
        padding: 0 15px;
    }
    .history-wrapper .timeline-box:not(:last-child) {
        margin-bottom: 30px;
    }
    .timeline-box .content {
        text-align: center;
    }
    .timeline-box:nth-child(2n):after,
    .timeline-box:nth-child(2n + 1):after {
        display: none;
    }
}

.mb-1-6, .my-1-6 {
    margin-bottom: 1.6rem;
}
.cardsecondarycustom {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.l-bg-cherry {
    background: linear-gradient(to right, #493240, darkred) !important;
    color: #fff;
}

.l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #4286f4) !important;
    color: #fff;
}

.l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
}

.l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
}

.cardsecondarycustom .card-statistic-3 .card-icon-large .fas, .cardsecondarycustom .card-statistic-3 .card-icon-large .far, .cardsecondarycustom .card-statistic-3 .card-icon-large .fab, .cardsecondarycustom .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
}
.backgroundcustom {
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: 10px 10px 5px 0px rgba(188, 188, 188, 0.75);
}
.cardsecondarycustom .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}