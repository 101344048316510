.contact__wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
}
.contact-info__wrapper {
    background-color: rgb(96, 96, 222);
}
.color--white {
    color: white !important;
    font-size: 2.75rem;
    text-decoration-line: underline;
}
.position-absolute {
    position: absolute;
    top: 0;
    left: 0;
}
.color--white-two {
    color: white !important;
    font-size: 1.125rem;
}
@media only screen and (max-width: 775px) {
    #contact-form {
        min-width: 87.5vw !important;
        position: relative;
        left: -100px;
        right: 0;
    }
    #contact-form {
        margin-left: 70px !important;
    }
}