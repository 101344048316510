#maxed-spacer {
    max-width: 100vw !important;
    overflow-x: scroll;
}
.contact-content {
    margin-left: -20px !important;
}
#about {
    max-width: 20vw !important;
}
.contact-content form h2 {
    margin-left: 17.5px !important;
}
@media only screen and (min-width: 775px) {
    #maxed-spacer {
        overflow-x: hidden !important;
        max-width: 100vw !important;
    }
}
.widemarkdown {
    min-width: 96vw !important;
    margin: 12.25px;
}
.top-faq-text {
    font-size: 20px !important;
    font-weight: bold !important;
    margin-bottom: -10px !important;
}
.faq {}

.middle-content-backgrounded {
    background-color: #F7F7F7 !important;
}
.image-bottom-left {
    min-height: 525px !important;
}
.image-bottom-secondary {
    min-width: 100% !important;
}
.banner-image-about-buyers{ 
    min-width: 100% !important;
}
@media only screen and (max-width: 775px) {
    .image-bottom-left {
        min-height: auto !important;
    }
}